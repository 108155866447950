<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
        >
            <el-row>
                <el-col :span="24">
                    <el-form-item
                        prop="activityCatId"
                        label="活动分类"
                        label-width="12em"
                    >
                        {{ detail.activityCatName }}
                    </el-form-item>
                    <el-form-item
                        prop="orgId"
                        label="组织机构"
                        label-width="12em"
                    >
                        {{ detail.orgName }}
                    </el-form-item>
                    <el-form-item
                        prop="activityType"
                        label="活动类型"
                        label-width="12em"
                    >
                        {{ detail.activityTypeName }}
                    </el-form-item>
                    <el-form-item
                        prop="activityName"
                        label="活动名称"
                        label-width="12em"
                    >
                        {{ detail.activityName }}
                    </el-form-item>
                    <el-form-item
                        prop="activityImage"
                        label="活动图片"
                        label-width="12em"
                    >
                        <ImageList
                            :data="detail.activityImageUrls"
                            :item-width="100"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="activityDesc"
                        label="活动描述"
                        label-width="12em"
                    >
                        <div
                            class="editor-content rich-text-content"
                            v-html="detail.activityDesc"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="activityAttachment"
                        label="活动附件"
                        label-width="12em"
                    >
                        <ImageList
                            :data="detail.activityAttachmentUrls"
                            :item-width="100"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="releaseTime"
                        label="发布时间"
                        label-width="12em"
                    >
                        {{ detail.releaseTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </el-form-item>
                    <el-form-item
                        prop="applyEndTime"
                        label="报名截止时间"
                        label-width="12em"
                    >
                        {{ detail.applyEndTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </el-form-item>
                    <el-form-item
                        prop="activityTime"
                        label="活动时间"
                        label-width="12em"
                    >
                        <div>
                            开始时间：{{ detail.activityTimeBegin | moment('YYYY-MM-DD  HH:mm:ss') }}
                        </div>
                        <div>
                            结束时间：{{ detail.activityTimeEnd | moment('YYYY-MM-DD  HH:mm:ss') }}
                        </div>
                    </el-form-item>
                    <el-form-item
                        prop="limitPeopleNum"
                        label="限制人数"
                        label-width="12em"
                    >
                        {{ detail.limitPeopleNum }}
                    </el-form-item>
                    <el-form-item
                        prop="limitPointNum"
                        label="限制积分"
                        label-width="12em"
                    >
                        {{ detail.limitPointNum }}
                    </el-form-item>
                    <el-form-item
                        prop="regionId"
                        label="活动地区"
                        label-width="12em"
                    >
                        {{ detail.regionName }}
                    </el-form-item>
                    <el-form-item
                        prop="address"
                        label="活动详细地址"
                        label-width="12em"
                    >
                        <XAddressWarp :dataset='addressInfo'></XAddressWarp>
                    </el-form-item>
                    <el-form-item
                        prop="contactFullName"
                        label="联系人姓名"
                        label-width="12em"
                    >
                        {{ detail.contactFullName }}
                    </el-form-item>
                    <el-form-item
                        prop="contactTel"
                        label="联系人电话"
                        label-width="12em"
                    >
                        {{ detail.contactTel }}
                    </el-form-item>
                    <el-form-item
                        prop="isNeedSignIn"
                        label="是否需要签到"
                        label-width="12em"
                    >
                        {{ detail.isNeedSignIn ? '是':'否' }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item
                        label-width="12em"
                    >
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
export default {
    name: 'ActivityView',
    mixins: [pagesMixin],
    data() {
        return {
            id: '',
            detail: {},
        };
    },
    computed: {
        addressInfo() {
            const { detail } = this;
            return {
                address: detail.address,
                longitude: +detail.addressLongitude,
                latitude: +detail.addressLatitude,
            };
        },
    },
    methods: {
        initDetail() {
            this.$api.Mb.Activity.getDetail({ id: this.id }).then(json => {
                const res = json.data.data;
                this.detail = res || {};
            });
        },
    },
    created() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.initDetail();
        }
    },
};
</script>

<style scoped lang="scss">
</style>
